<template>
  <Modal :activeModal="isActiveModal" @closeModal="closeModal" :widthModal="600">
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
          {{ $gettext('Editar limite da revenda') }}
          <v-spacer></v-spacer>
          <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>
      <v-col class="d-flex justify-center align-center" style="min-height: 300px; width: 100%;" v-if="loadingModal">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>

      <div class="box-form box-form-products" v-else>
        <v-form>
          <v-card-text class="modal-cadastro-form">
            <v-card-title>
              Revenda: {{ entity.cod }} - {{ entity.name }}
            </v-card-title>
            <v-row>
              <v-col cols="12" class="modal-cadastro-form-col-imput" sm="3">
                <v-select
                  :error-messages="produtoIdErrors"
                  :items="listProducts"
                  label="Produtos"
                  v-model="itemEntityProducts.productId" 
                  @input="$v.itemEntityProducts.productId.$touch()"
                  @blur="$v.itemEntityProducts.productId.$touch()"
                  :disabled="activeForm"
              />
              </v-col>
              <v-col cols="12" class="modal-cadastro-form-col-imput" sm="3">
                <vmoney 
                  v-model="itemEntityProducts.debtLimit"
                  :label="'Limite de Crédito'"
                  :options="limit"
                  :error="limitErrors"
                  :disabled="activeForm"
                />
              </v-col>
              <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4">
                <v-btn
                  :color="variables.colorPrimary"
                  @click="cancel"
                  class="br-btn br-btn-cancelar br-btn-entity-products mr-2"
                  :width="70"
                  :disabled="activeForm"
                >Cancelar</v-btn>
                <v-btn
                  type="submit"
                  :color="variables.colorPrimary"
                  @click.prevent="submit"
                  class="br-btn br-btn-entity-products"
                  :loading="loading"
                  :width="70"
                  :disabled="activeForm"
                  >Salvar</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>

        <v-card-text class="modal-cadastro-form">
          <v-data-table
            :headers="headers"
            :items="listItensEntitiesPoducts"
            width="300px"
            height="auto"
            flat
            :items-per-page="5"
            :loading="loadingTable"
            dense
          >

            <template v-slot:item.product.name="{ item }">
              {{ item.product.name}}
            </template>

            <template v-slot:item.debtLimit="{ item }">
              {{ item.debtLimit | currency }}
            </template>

            <!-- <template v-slot:item.comission="{ item }">
              {{ item.comission | percentage }}
            </template> -->

            <template v-slot:item.action="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
              >
                edit
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>
        <!-- <comissao :entity="entity" /> -->
      </div>
    </v-card>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import Events from '@/core/service/events'
// import { validCnpj } from '@/core/service/utils'
// import { cnpj } from 'cpf-cnpj-validator'
import variables from '@/assets/styles/helpers/_variables.scss'
import vmoney from '@/views/components/vMoney'
import validacao from './validacaoProducts'

// const validCNPJ = (params) => {
//   console.log(params)
// }

export default {
  name: 'BrModalCadastroAts',
  mixins: [validationMixin],
  directives: {mask},
  components: {
    vmoney,
    Modal: () => import('@/views/components/modal'),
    // comissao: () => import('./comissao')
  },
  props: {
    entity: {
      type: Object,
      default: () => ({
        parentId: ''
      })
    },

    isActiveModal: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false,
    search: '',
    headers: [
      { class: 'table-header', text: 'ID Produto', value: 'productId' },
      { class: 'table-header', text: 'Produto', value: 'product.name' },
      { class: 'table-header', text: 'Comissão', value: 'comission' },
      { class: 'table-header', text: 'Limite de Crédito', value: 'debtLimit' },
      { class: 'table-header', text: '', value: 'action', sortable: false },
    ],
    divider: '/',
    desserts: [],
    itemPerPage: [5, 10, 50, 100, {text: 'Todos', value: -1}],
    comission: {
      locale: 'pt-BR',
      prefix: '',
      suffix: ' %',
      length: 4,
      precision: 2
    },
    // limit: {
    //   prefix: 'R$ ',
    //   precision: 2,
    //   thousands: '.',
    //   decimal: ','
    // },
    limit: {
      locale: "pt-BR",
      prefix: "R$ ",
      suffix: "",
      length: 11,
      precision: 2
    },
    form: {
      comission: '',
      debtLimit: ''
    },
    loadingTable: false,
    loadingModal: false
  }),
  watch: {
    async isActiveModal (val) {
      if (val) {
        this.loadingModal = true
        await this.obterItensSelectProducts()
        await this.obterEntityProduct({ entityId: this.entity.id })
        await this.obterItensSelectParentId({ id: this.entity.parentId })
        this.loadingModal = false
      }
    }
  },
  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('estabelecimento', ['listProducts', 'itemEntityProducts', 'listItensEntitiesPoducts']),
    
    ...validacao,

    variables: () => variables,
    isEdit () {
      return this.itemEntityProducts.id ? !!1 : !!0
    },
    activeForm () {
      return this.itemEntityProducts.id ? !!0 : !!1
    },
  },
  mounted () {
  },
  methods: {
    ...mapActions('estabelecimento', ['cadastrarEntityProduct', 'obterItensSelectProducts', 'obterEntityProduct', 'getEntityProduct', 'editEntityProduct', 'editEntityProductLimit', 'deletarItemEntityProduct', 'limparItemEntityProduct']),
    ...mapActions('estabelecimentoComissao', {
      obterItensSelectParentId: 'obterItensSelect',
    }),

    closeModal () {
      this.cancel()
      this.$emit('close-modal', true)
    },
    cancel () {
      this.limparItemEntityProduct()
      this.$v.itemEntityProducts.$reset()
    },

    editItem (item) {
      // this.form.comission = item.comission
      // this.form.debtLimit = 3000
      // console.log(this.form)
      this.getEntityProduct(item)
    },

    deleteItem (item) {
      this.$swal({
        icon: 'warning',
        text: `Tem certeza que deseja excluir`,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.value) {
          this.deletarItemEntityProduct({id: item.id}).then(() =>{
            this.cancel()
            this.obterEntityProduct({ entityId: this.entity.id })
            const msg = {
              toggle: true,
              type: 'success',
              msg: 'Item excluído com sucesso!'
            }
            Events.$emit('snackbarCadastro::msg', msg)
          }).catch(err => {
            const msg = {
              toggle: true,
              type: 'error',
              msg: err.error
            }
            Events.$emit('snackbarCadastro::msg', msg)
          })
        }
      })
    },

    submit () {
      if (this.$v.itemEntityProducts.$invalid || !this.itemEntityProducts.id ) { 
        this.$v.itemEntityProducts.$touch()
        return false 
      }
      this.loading = true

      const dados = {
        entityId: this.entity.id,
        productId: this.itemEntityProducts.productId,
        debtLimit: this.itemEntityProducts.debtLimit
      }

      if (this.itemEntityProducts.id || find !== undefined) {
        dados.id = this.itemEntityProducts.id || find.id

        this.editEntityProductLimit(dados)
        .then(() => {
          this.obterEntityProduct({ entityId: this.entity.id })
          this.cancel()
          const msg = {
            toggle: true,
            type: 'success',
            msg: 'Edição realizado com sucesso!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
        .catch(err => {
          const msg = {
            toggle: true,
            type: 'error',
            msg: err.error
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
        .finally(() => this.loading = false)
      } else {
        this.cadastrarEntityProduct(dados)
        .then(() => {
          this.obterEntityProduct({ entityId: this.entity.id })
          this.cancel()
          const msg = {
            toggle: true,
            type: 'success',
            msg: 'Cadastro realizado com sucesso!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
        .catch(err => {
          const msg = {
            toggle: true,
            type: 'error',
            msg: err.error
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
        .finally(() => this.loading = false)
      }
    },
  },

  validations () {
    return  {
      itemEntityProducts: {
        productId: { required },
        comission: { required },
        debtLimit: { required }
      }
    }
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/formularios';
  .br-btn-entity-products {
    margin-top: 10px;
    min-width: auto !important;
    font-size: 0.8em !important;
  }
</style>